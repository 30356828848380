import { computed, InjectionKey, onMounted, onUnmounted, reactive, toRef, watch } from "vue";
import { MessageOrId } from "../../i18n";

export type WuxAccordionProps = {
    /** Display the items with less spacing and save space when needed */
    isDense?: boolean;
    /** Only one accordion panel can be expanded at a time */
    isExclusive?: boolean;
    /** Show a loading indicator */
    isLoading?: boolean;
};

export type WuxAccordionPanelProps = {
    /** To display the item in the disabled style */
    isDisabled?: boolean;
    /** To display the accordion as expanded. Please note, if WuxAccordion is in 'exclusive' mode, only the last 'isExpanded' accordion will be expanded */
    isExpanded?: boolean;
    /** To apply reveal style to the accordion panel */
    asReveal?: boolean;
    /** Message for title of the accordion panel */
    titleMsg?: MessageOrId;
    /** Text for title of the accordion panel (only used if titleMsg is not provided) */
    title?: string;
};

/**
 * types to handle provide/inject mechanism
 */
export const accordionInjectionKey: InjectionKey<ReturnType<typeof useAccordionState>["registerAccordionPanel"]> =
    Symbol("accordion");

export const useAccordionState = (props?: WuxAccordionProps) => {
    const openPanelIds = reactive(new Set<symbol>());
    const allIds = reactive(new Set<symbol>());
    const disabledIds = reactive(new Set<symbol>());

    const openAll = () => {
        if (props?.isExclusive) {
            // eslint-disable-next-line no-console
            console.warn("openAll is not allowed when isExclusive is true");
            return;
        }

        allIds.forEach((id) => {
            if (!disabledIds.has(id)) {
                openPanelIds.add(id);
            }
        });
    };

    const closeAll = () => {
        openPanelIds.forEach((id) => {
            if (!disabledIds.has(id)) {
                openPanelIds.delete(id);
            }
        });
    };

    const registerAccordionPanel = (panelProps?: WuxAccordionPanelProps) => {
        const panelId = Symbol();
        const isExpanded = computed(() => openPanelIds.has(panelId));

        onMounted(() => {
            allIds.add(panelId);
        });
        onUnmounted(() => {
            allIds.delete(panelId);
            openPanelIds.delete(panelId);
            disabledIds.delete(panelId);
        });
        watch(
            () => panelProps?.isDisabled,
            () => {
                if (panelProps?.isDisabled) {
                    disabledIds.add(panelId);
                } else {
                    disabledIds.delete(panelId);
                }
            },
            { immediate: true },
        );

        return {
            isDense: toRef(() => props?.isDense),
            isExpanded,

            toggle() {
                if (panelProps?.isDisabled) return;
                const wasExpanded = isExpanded.value;
                if (props?.isExclusive) {
                    openPanelIds.clear();
                }
                if (wasExpanded) {
                    openPanelIds.delete(panelId);
                } else {
                    openPanelIds.add(panelId);
                }
            },

            setExpanded(expanded: boolean) {
                if (expanded) {
                    openPanelIds.add(panelId);
                } else {
                    openPanelIds.delete(panelId);
                }
            },
        };
    };

    return {
        registerAccordionPanel,
        openAll,
        closeAll,
    };
};
