export const FetchAbortReasons = {
    TIMEOUT: "TIMED OUT",
    OFFLINE: "OFFLINE",
    BROWSER_UNLOAD: "BROWSER_UNLOAD",
};

/**
 *
 * This code is used inside the application to handle the scenario where a user is redirected
 * to a new URL or closes the browser, which might cause ongoing fetch requests to fail.
 * To prevent the "failed to fetch" errors, we abort all ongoing requests before redirecting / closing.
 *
 * Consumers of this abort controller can detect if a fetch was aborted in this manner
 * by checking the reason provided when the request is aborted.
 *
 **/
const globalAbortController = new AbortController();
/** Should only be used in `ui/libs/auth` */
export const globalAbortSignal = globalAbortController.signal;
/** Should only be used in `ui/libs/auth` and `ui/libs/skeleton` */
export const abortAllRequests = () => globalAbortController.abort(FetchAbortReasons.BROWSER_UNLOAD);

export const _getTimeoutController = (timeout: number, signal?: AbortSignal): AbortSignal => {
    // early escape (if already aborted, just forward that signal)
    if (signal?.aborted) return signal;

    const signals = [globalAbortSignal, AbortSignal.timeout(timeout)];
    if (signal) signals.push(signal);
    return AbortSignal.any(signals);
};
