<script lang="ts" setup>
import { computed, inject, watch } from "vue";
import { useMsgFormatter } from "../../i18n";
import { Icons } from "../../icons";
import WuxIcon from "../WuxIcon/WuxIcon.vue";
import { accordionInjectionKey, useAccordionState, WuxAccordionPanelProps } from "./WuxAccordion.core";

const props = defineProps<WuxAccordionPanelProps>();
const emit = defineEmits<{
    "update:isExpanded": [value: boolean];
}>();

const slots = defineSlots<{
    /** Content to display inside the accordion panel */
    default?(): void;
    /** Include custom header content in the accordion panel. The custom header an be used together with default `title` or `titleMsg` */
    accordionCustomHead?(): void;
}>();

const { rawM } = useMsgFormatter();

const { registerAccordionPanel: registerAccordionPanelDefault } = useAccordionState();
const registerAccordionPanel = inject(accordionInjectionKey, registerAccordionPanelDefault);

const { toggle, isExpanded, setExpanded } = registerAccordionPanel(props);

const accordionClasses = computed(() => ({
    "wux-accordion-panel--disabled": props.isDisabled,
    "wux-accordion-panel--expanded": isExpanded?.value,
    "wux-accordion-panel--reveal": props.asReveal,
}));

watch(
    () => props.isExpanded,
    () => {
        setExpanded(props.isExpanded ?? false);
    },
    { immediate: true },
);

watch(isExpanded, () => {
    emit("update:isExpanded", isExpanded.value);
});
</script>

<template>
    <div class="wux-accordion-panel" :class="accordionClasses">
        <div
            class="wux-accordion-panel__head"
            @click="toggle"
            :tabindex="props.isDisabled ? -1 : 0"
            @keydown.space.prevent="toggle"
        >
            <div class="wux-accordion-panel__head__icon">
                <WuxIcon
                    :src="Icons.chevron_down"
                    style="--_wux-icon-size: var(--wux-accordion-panel-head-icon-size)"
                />
            </div>
            <div class="wux-accordion-panel__head__title-wrapper">
                <span v-if="rawM(props.titleMsg, props.title)" class="wux-accordion-panel__head__title">
                    {{ rawM(props.titleMsg, props.title) }}
                </span>
                <div v-if="slots['accordionCustomHead']" class="wux-accordion-panel__head__custom">
                    <slot name="accordionCustomHead" />
                </div>
            </div>
        </div>
        <div class="wux-accordion-panel__body">
            <Transition name="content-transition">
                <div v-if="isExpanded" class="wux-accordion-panel__body-wrapper">
                    <div class="wux-accordion-panel__body-content">
                        <slot />
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>

<style lang="scss">
@use "../../../assets/styles/mixins.scss";

.wux-accordion-panel {
    --wux-accordion-panel-v-padding: 0;
    --wux-accordion-panel-h-padding: 0;
    --wux-accordion-panel-head-icon-size: 24px;
    --wux-accordion-panel-title-size: 1rem;

    $wuxAccordionPanelAnimationSpeed: 0.25s;

    // accordion panel head
    &__head {
        display: flex;
        gap: 1.5rem;
        align-items: center;
        padding: var(--wux-accordion-panel-h-padding) var(--wux-accordion-panel-v-padding);
        position: relative;
        padding-right: 0.5rem; // for focus-visible border

        // head title
        &__title-wrapper {
            flex: 1;
        }
        &__title {
            font-size: var(--wux-accordion-panel-title-size);
            + * {
                margin-top: 0.5rem;
            }
        }

        // head icon
        &__icon {
            overflow: hidden; // to fix animation clipping
            display: flex;
            .wux-icon {
                transition: transform $wuxAccordionPanelAnimationSpeed;
            }
        }

        @include mixins.focus-outline($inset: true, $dense: false);
        &:focus-visible {
            border-color: transparent;
        }
    }

    // accordion panel body
    &__body {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows $wuxAccordionPanelAnimationSpeed ease;
        &-wrapper {
            overflow: hidden;
        }
        &-content {
            padding: 0 var(--wux-accordion-panel-v-padding);
        }
    }

    // hover state
    &:not(&--disabled) {
        .wux-accordion-panel__head {
            &:hover {
                cursor: pointer;
            }
        }
    }

    // panel expanded state
    &--expanded {
        > .wux-accordion-panel {
            &__head {
                .wux-accordion-panel__head__icon {
                    .wux-icon {
                        transform: rotate(180deg);
                    }
                }
            }
            &__body {
                grid-template-rows: 1fr;
            }
        }
    }

    // panel disabled state
    &--disabled {
        .wux-accordion-panel {
            &__head {
                color: var(--wawi-color-neutral-500);
            }
        }
    }

    // transition
    .content-transition-enter-active,
    .content-transition-leave-active {
        transition: grid-template-rows $wuxAccordionPanelAnimationSpeed ease;
    }

    // custom 'reveal' style
    &--reveal {
        .wux-accordion-panel {
            &__head {
                margin: 1rem 0 0.5rem;
                &__title {
                    font-weight: 600;
                }
            }
        }
    }
}

// custom adjustments of panels used in the accordion
.wux-accordion {
    .wux-accordion-panel {
        --wux-accordion-panel-border-color: var(--wawi-color-neutral-300);
        --wux-accordion-panel-h-padding: 0.5rem;
        --wux-accordion-panel-v-padding: 0.5rem;

        &:first-child {
            .wux-accordion-panel__head {
                border-top-width: 1px;
            }
        }

        &__head {
            align-items: flex-start;
            border-color: transparent;
            border-style: solid;
            border-width: 0 1px;
        }
        &__body {
            border-top: 1px solid var(--wux-accordion-panel-border-color);
            &-content {
                background: var(--wawi-color-grayscale-white);
                border: 1px solid var(--wux-accordion-panel-border-color);
                border-top: none;
                padding: 0.5rem var(--wux-accordion-panel-v-padding);
            }
        }

        &--expanded {
            > .wux-accordion-panel {
                &__body {
                    border-top: 0;
                }
                &__head {
                    background: var(--wawi-color-primary-100);
                    border-color: var(--wux-accordion-panel-border-color);
                    .wux-accordion-panel__head__icon {
                        .wux-icon {
                            color: var(--wawi-color-primary-500);
                        }
                    }
                    .wux-accordion-panel__head__title {
                        color: var(--wawi-color-primary-500);
                    }
                }
            }
        }

        // hover and active styles
        &:not(&--disabled) {
            .wux-accordion-panel__head {
                &:hover {
                    background: var(--wawi-color-neutral-200);
                }
                &:active {
                    background: var(--wawi-color-neutral-300);
                }
            }
        }

        // adjustments for nested accordions
        .wux-accordion {
            .wux-accordion-panel:last-child {
                .wux-accordion-panel__body {
                    border: 0;
                }
            }
        }
    }
    // dense
    &--dense {
        > .wux-accordion-panel {
            --wux-accordion-panel-h-padding: 0.125rem;
            .wux-accordion-panel {
                &__head {
                    &__icon {
                        .wux-icon {
                            margin-top: 0.1rem; // to align with the title
                            --wux-accordion-panel-head-icon-size: 18px;
                        }
                    }
                }
            }
        }
    }
}
</style>
