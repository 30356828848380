<script lang="ts" setup>
import { computed } from "vue";
import { MessageOrId, useMsgFormatter } from "../../i18n";
import { Icon, Icons } from "../../icons";

export type ButtonVariant = "default" | "text" | "outline";

const props = withDefaults(
    defineProps<{
        /**
         * You can use as values here e.g. `Icons.x`.
         */
        src: Icon;
        size?: number;
        /**
         * Can be any CSS color value.
         */
        color?: string;
        title?: string;
        titleMsg?: MessageOrId;
    }>(),
    { size: 24, color: "currentColor" },
);

// calculate CSS variables to use them in the style section with `v-bind`.
const style = computed(() => ({
    iconSize: props.size + "px",
    spinnerColor: props.color !== "currentColor" ? props.color : "var(--wawi-color-primary-500)",
}));

const { rawM } = useMsgFormatter();
</script>

<template>
    <div v-if="props.src === Icons.spinner" :title="rawM(props.titleMsg, props.title)" class="wux-icon--spinner" />
    <svg v-else class="wux-icon" :fill="props.color" viewBox="0 0 32 32">
        <path :d="props.src"></path>
        <title v-if="rawM(props.titleMsg, props.title)">{{ rawM(props.titleMsg, props.title) }}</title>
    </svg>
</template>
<style lang="scss">
.wux-icon {
    --_wux-icon-size: v-bind("style.iconSize");
    width: var(--_wux-icon-size);
    height: var(--_wux-icon-size);
}
.wux-icon--spinner {
    --wux-spinner-size: v-bind("style.iconSize");
    width: var(--wux-spinner-size);
    height: var(--wux-spinner-size);
    border-radius: 50%;
    position: relative;
    animation: 0.85s cubic-bezier(0.43, 0.35, 0.57, 0.84) 0s infinite normal none running wux-icon-spinner-rotate;

    &::before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0;
        border-radius: 50%;
        outline: 1px solid v-bind("style.spinnerColor");
        outline-offset: -2px; // to make it the same outer size as the other icons
        animation: 0.85s cubic-bezier(0.43, 0.35, 0.57, 0.84) 0s infinite normal none running wux-icon-spinner-clip;
    }
}

@keyframes wux-icon-spinner-rotate {
    0% {
        transform: rotate(-300deg);
    }
    100% {
        transform: rotate(60deg);
    }
}

@keyframes wux-icon-spinner-clip {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    100% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
}
</style>
