import { __, MessageOrId } from "@ui/components";

export enum ModuleScope {
    HQ = "HQ",
    WH = "WH",
    HQ_WH = "HQ_WH",
    INT = "INT",
    ALL = "ALL",
}

export type Module = {
    name: string;
    descriptionMsg: MessageOrId;
    url: string;
    scope: ModuleScope;
};

// Defines the list of all current UI modules that we know of and the policy needed to access the module.
// This list is automatically sorted alphabetically by name after definition
export const modules: Readonly<Module[]> = (
    [
        {
            name: "FAM",
            descriptionMsg: __("ui.libs.module-name.fam"),
            url: "/module/fam/",
            scope: ModuleScope.INT,
        },
        {
            name: "DHQ",
            descriptionMsg: __("ui.libs.module-name.dhq"),
            url: "/module/dhq/",
            scope: ModuleScope.HQ,
        },
        {
            name: "RAP",
            descriptionMsg: __("ui.libs.module-name.rap"),
            url: "/module/rap/",
            scope: ModuleScope.HQ,
        },
        {
            name: "REP",
            descriptionMsg: __("ui.libs.module-name.rep"),
            url: "/module/rep/",
            scope: ModuleScope.HQ,
        },
        {
            name: "SFO",
            descriptionMsg: __("ui.libs.module-name.sfo"),
            url: "/module/sfo/",
            scope: ModuleScope.WH,
        },
        {
            name: "ASP",
            descriptionMsg: __("ui.libs.module-name.asp"),
            url: "/module/asp/",
            scope: ModuleScope.WH,
        },
        {
            name: "TAB",
            descriptionMsg: __("ui.libs.module-name.tab"),
            url: "/module/tab/",
            scope: ModuleScope.HQ_WH,
        },
        {
            name: "RVW",
            descriptionMsg: __("ui.libs.module-name.rvw"),
            url: "/module/rvw/",
            scope: ModuleScope.HQ_WH,
        },
        {
            name: "WAW-ITEM",
            descriptionMsg: __("ui.libs.module-name.waw-item"),
            url: "/module/waw-item/",
            scope: ModuleScope.HQ_WH,
        },
        {
            name: "WAW-SPECIALS",
            descriptionMsg: __("ui.libs.module-name.waw-specials"),
            url: "/module/waw-specials/",
            scope: ModuleScope.HQ_WH,
        },
        {
            name: "WAW-SUPPLIER",
            descriptionMsg: __("ui.libs.module-name.waw-supplier"),
            url: "/module/waw-supplier/",
            scope: ModuleScope.HQ_WH,
        },
        {
            name: "WSM",
            descriptionMsg: __("ui.libs.module-name.wsm"),
            url: "/module/wsm/",
            scope: ModuleScope.INT,
        },
        {
            name: "IAM",
            descriptionMsg: __("ui.libs.module-name.iam"),
            url: "/module/iam/",
            scope: ModuleScope.INT,
        },
        {
            name: "SAT",
            descriptionMsg: __("ui.libs.module-name.sat"),
            url: "/module/sat/",
            scope: ModuleScope.INT,
        },
        {
            name: "GRC",
            descriptionMsg: __("ui.libs.module-name.grc"),
            url: "/module/grc/",
            scope: ModuleScope.WH,
        },
        {
            name: "ADR",
            descriptionMsg: __("ui.libs.module-name.adr"),
            url: "/module/adr/",
            scope: ModuleScope.WH,
        },
        {
            name: "MDE",
            descriptionMsg: __("ui.libs.module-name.mde"),
            url: "/module/mde/",
            scope: ModuleScope.WH,
        },
        {
            name: "PAR",
            descriptionMsg: __("ui.libs.module-name.par"),
            url: "/module/par/",
            scope: ModuleScope.WH,
        },
        {
            name: "MIL",
            descriptionMsg: __("ui.libs.module-name.mil"),
            url: "/module/mil/",
            scope: ModuleScope.WH,
        },
        {
            name: "KEP",
            descriptionMsg: __("ui.libs.module-name.kep"),
            url: "/module/kep/",
            scope: ModuleScope.HQ,
        },
        {
            name: "XWH",
            descriptionMsg: __("ui.libs.module-name.xwh"),
            url: "/module/xwh/",
            scope: ModuleScope.WH,
        },
        {
            name: "SUDO",
            descriptionMsg: __("ui.libs.module-name.sudo"),
            url: "/module/sudo/",
            scope: ModuleScope.ALL,
        },
        {
            name: "INV",
            descriptionMsg: __("ui.libs.module-name.inv"),
            url: "/module/inv/",
            scope: ModuleScope.WH,
        },
        {
            name: "TDB",
            descriptionMsg: __("ui.libs.module-name.tdb"),
            url: "/module/tdb/",
            scope: ModuleScope.HQ,
        },
        {
            name: "DBC",
            descriptionMsg: __("ui.libs.module-name.dbc"),
            url: "/module/dbc/",
            scope: ModuleScope.HQ_WH,
        },
        {
            name: "DIS",
            descriptionMsg: __("ui.libs.module-name.dis"),
            url: "/module/dis/",
            scope: ModuleScope.WH,
        },
        {
            name: "SHP",
            descriptionMsg: __("ui.libs.module-name.shp"),
            url: "/module/shp/",
            scope: ModuleScope.WH,
        },
        {
            name: "EMC",
            descriptionMsg: __("ui.libs.module-name.emc"),
            url: "/module/emc/",
            scope: ModuleScope.WH,
        },
        {
            name: "YWH",
            descriptionMsg: __("ui.libs.module-name.ywh"),
            url: "/module/ywh/",
            scope: ModuleScope.WH,
        },
        {
            name: "TIL",
            descriptionMsg: __("ui.libs.module-name.til"),
            url: "/module/til/",
            scope: ModuleScope.WH,
        },
        {
            name: "CPP",
            descriptionMsg: __("ui.libs.module-name.cpp"),
            url: "/module/cpp/",
            scope: ModuleScope.WH,
        },
        {
            name: "ALO",
            descriptionMsg: __("ui.libs.module-name.alo"),
            url: "/module/alo/",
            scope: ModuleScope.WH,
        },
        {
            name: "FWD",
            descriptionMsg: __("ui.libs.module-name.fwd"),
            url: "/module/fwd/",
            scope: ModuleScope.WH,
        },
        {
            name: "AMD",
            descriptionMsg: __("ui.libs.module-name.amd"),
            url: "/module/amd/",
            scope: ModuleScope.WH,
        },
        {
            name: "MMO",
            descriptionMsg: __("ui.libs.module-name.mmo"),
            url: "/module/mmo/",
            scope: ModuleScope.HQ_WH,
        },
        {
            name: "RSD",
            descriptionMsg: __("ui.libs.module-name.rsd"),
            url: "/module/rsd/",
            scope: ModuleScope.WH,
        },
        {
            name: "STW",
            descriptionMsg: __("ui.libs.module-name.stw"),
            url: "/module/stw/",
            scope: ModuleScope.WH,
        },
        {
            name: "EGIN",
            descriptionMsg: __("ui.libs.module-name.egin"),
            url: "/module/egin/",
            scope: ModuleScope.WH,
        },
        {
            name: "YHQ",
            descriptionMsg: __("ui.libs.module-name.yhq"),
            url: "/module/yhq/",
            scope: ModuleScope.HQ,
        },
        {
            name: "BDI",
            descriptionMsg: __("ui.libs.module-name.bdi"),
            url: "/module/bdi/",
            scope: ModuleScope.HQ,
        },
        {
            name: "WPC",
            descriptionMsg: __("ui.libs.module-name.wpc"),
            url: "/module/wpc/",
            scope: ModuleScope.WH,
        },
        {
            name: "TCO",
            descriptionMsg: __("ui.libs.module-name.tco"),
            url: "/module/tco/",
            scope: ModuleScope.HQ_WH,
        },
        {
            name: "ALMA",
            descriptionMsg: __("ui.libs.module-name.alma"),
            url: "/module/alma/",
            scope: ModuleScope.WH,
        },
    ] satisfies Module[]
).sort((m1, m2) => m1.name.localeCompare(m2.name));
